import i18n from '@/i18n';

export default () => {
  const exportOptions = [
    { id: 'contact_id', name: i18n.t('contact-fields.id') },
    { id: 'first_name', name: i18n.t('contact-fields.first-name') },
    { id: 'last_name', name: i18n.t('contact-fields.last-name') },
    { id: 'name', name: i18n.t('contact-fields.full-name') },
    { id: 'email', name: i18n.t('contact-fields.e-mail') },
    { id: 'phone', name: i18n.t('contact-fields.phone') },
    { id: 'address', name: i18n.t('contact-fields.address') },
    { id: 'country', name: i18n.t('contact-fields.country') },
    { id: 'city', name: i18n.t('contact-fields.city') },
    { id: 'ip', name: i18n.t('contact-fields.ip-address') },
    { id: 'optin_date', name: i18n.t('contact-fields.optin-date') },
    { id: 'page_visits', name: i18n.t('contact-fields.page-visits') },
    { id: 'unique_page_visits', name: i18n.t('contact-fields.unique-page-visits') },
    { id: 'webinars_registered', name: i18n.t('contact-fields.webinars-registered') },
    { id: 'products_amount', name: i18n.t('contact-fields.products-amount') },
    { id: 'memberships', name: i18n.t('contact-fields.memberships') },
    { id: 'completed_lessons', name: i18n.t('contact-fields.completed-lessons') },
    { id: 'completed_courses', name: i18n.t('contact-fields.completed-courses') },
    { id: 'activity_level', name: i18n.t('contact-fields.activity-level') },
    { id: 'type', name: i18n.t('contact-fields.type') },
    { id: 'revenue', name: i18n.t('contact-fields.revenue') },
    { id: 'mails_received', name: i18n.t('contact-fields.mails-received') },
    { id: 'mails_opened', name: i18n.t('contact-fields.mails-opened') },
    { id: 'mails_clicked', name: i18n.t('contact-fields.mails-clicked') },
    { id: 'mails_click_rate', name: i18n.t('contact-fields.mails-click-rate') },
    { id: 'mails_open_rate', name: i18n.t('contact-fields.mails-open-rate') },
    { id: 'shopping_behavior', name: i18n.t('contact-fields.shopping-behavior') },
    { id: 'sourceId1', name: i18n.t('contact-fields.sourceId1') },
    { id: 'sourceId2', name: i18n.t('contact-fields.sourceId2') },
    { id: 'sourceId3', name: i18n.t('contact-fields.sourceId3') },
    { id: 'sourceId4', name: i18n.t('contact-fields.sourceId4') },
    // { id: 'customer_type_division', name: i18n.t('contact-fields.unique') },
    // { id: 'tags', name: i18n.t('contact-fields.tags') },
    { id: 'created_at', name: i18n.t('contact-fields.created-at') },
    { id: 'source', name: i18n.t('contact-fields.source') },
  ];

  const selected = ['first_name', 'last_name', 'email', 'phone', 'address', 'country', 'city', 'revenue'];
  const exportValue = exportOptions.filter(({ id }) => selected.includes(id));

  const xlsx = { id: 'xlsx', name: 'Microsoft Excel' };
  const csv = { id: 'csv', name: 'CSV' };

  const exportAsValue = xlsx;
  const exportAsOptions = [xlsx, csv];

  return {
    exportValue,
    exportOptions,
    exportAsValue,
    exportAsOptions,
  };
};
