<script>
import EzPopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import { URL_API_CONTACTS } from '@dmant/ez-env-common';
import { hide } from '@/services/modal.js';
import contactsManager from '@/services/contactsManager';
import downloadFile from '@/services/downloadFile';
import exportHelper from './export';

export default {
  name: 'EzfExportContacts',
  components: { EzPopupContainer },
  props: {
    funnelId: {
      type: String,
      required: true,
    },
  },
  data() {
    const {
      exportValue,
      exportOptions,
      exportAsValue,
      exportAsOptions,
    } = exportHelper();
    return {
      exportWithTags: false,
      exportValue,
      exportOptions,
      exportAsValue,
      exportAsOptions,
      exportInProgress: false,
      exportId: null,
      timerId: null,
    };
  },
  computed: {

  },
  methods: {
    onClick() {
      if (!this.exportInProgress) {
        hide('export-contacts-dialog', false);
      }
    },
    async createExport() {
      this.exportInProgress = true;
      const params = {
        fields: this.exportValue.map((item) => item.id),
        format: this.exportAsValue.id,
        tags: this.exportWithTags,
        funnel: this.funnelId,
      };
      try {
        const { id } = await contactsManager.createExport(params);
        this.exportId = id;
        this.checkAgain();
      } catch (e) {
        this.showError(e);
      }
    },
    async checkExportStatus() {
      if (this.exportId) {
        try {
          const { status, filename } = await contactsManager.checkExport(this.exportId);
          switch (status) {
            case 'done':
              downloadFile(`${URL_API_CONTACTS}/${filename}`);
              this.$notify({
                data: {
                  type: 'success',
                  content: this.$t('exportContacts.export-success'),
                },
              });
              hide('export-contacts-dialog', true);
              break;
            case 'process':
            case 'new':
              this.checkAgain();
              break;
            case 'error':
              this.showError({ message: this.$t('exportContacts.export-error') });
              break;
            default:
              break;
          }
        } catch (e) {
          this.showError(e);
        }
      }
    },
    checkAgain() {
      this.timerId = setTimeout(() => {
        this.checkExportStatus();
      }, 5000);
    },
    showError(e) {
      this.exportId = null;
      this.exportInProgress = false;
      this.$notify({
        data: {
          type: 'error',
          content: e.message || e,
        },
      });
      console.error(e);
    },
  },
  destroyed() {
    clearTimeout(this.timerId);
  },
};
</script>

<template>
  <ez-popup-container
    service-name="funnels"
    @close="onClick(false)"
    icon-header="download"
  >
    <template slot="header">
      {{ $t('exportContacts.title') }}
    </template>

    <ez-form v-if="!exportInProgress">
      <ez-form-item :label="$t('exportContacts.form.export-fields')">
        <ez-select
          v-model="exportValue"
          :options="exportOptions"
          track-by="id"
          item-label="name"
          multiple
          taggable
        />
      </ez-form-item>
      <ez-form-item>
        <ez-row>
          <ez-col>
            <ez-checkbox v-model="exportWithTags">
              {{ $t('exportContacts.form.include-tags') }}
            </ez-checkbox>
          </ez-col>
        </ez-row>
      </ez-form-item>
      <ez-form-item>
        <ez-row>
          <ez-col size="9">
            <ez-select
              v-model="exportAsValue"
              :label="$t('exportContacts.form.export-format')"
              :options="exportAsOptions"
              item-label="name"
              track-by="id"
            />
          </ez-col>
        </ez-row>
      </ez-form-item>
    </ez-form>
    <template v-else>
      <div :class="$style['export-in-progress']">
        <div :class="$style['export-in-progress__spinner']">
          <ez-preloader :value="true" :stop="false" />
        </div>
        <div :class="$style['export-in-progress__text']">
          {{ $t('exportContacts.export-in-progress-text') }}
          <br/>
          {{ $t('exportContacts.export-in-progress-text-wait') }}
        </div>
      </div>
    </template>

    <template slot="footer">
      <ez-button-group justify="end" v-if="!exportInProgress">
        <ez-button type="secondary" @click="onClick(false)">
          {{ $t('exportContacts.buttons.cancel') }}
        </ez-button>
        <ez-button type="primary" @click="createExport">
          {{ $t('exportContacts.buttons.export') }}
        </ez-button>
      </ez-button-group>
    </template>
  </ez-popup-container>
</template>

<style lang="scss" module>
.export-in-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;

  &__spinner {
    margin: 40px 0;
  }

  &__text {
    margin-top: 34px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #555555;
  }
}
</style>
